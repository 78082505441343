import { FormEvent, useState } from "react";
import { Form, FormGroup, FormLabel, FormControl, Row, Col } from "react-bootstrap";
import PasswordFormControl from "../../sharedCommonComponents/components/PasswordFormControl";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { sendPostRequest } from "../../sharedCommonComponents/helpers/StoringHelpers";
import { Models } from "../types/models";
import { Center } from "../../sharedCommonComponents/components/Center";
import { AsyncButton } from "../../sharedCommonComponents/components/AsyncButton";
import { showErrorAlert } from "../../sharedCommonComponents/helpers/AlertHelpers";

interface LoginFormProps {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult) => void;
}

export const LoginForm = (props: LoginFormProps) => {

    const { onLoggedIn } = props;

    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ isLoggingIn, setIsLoggingIn ] = useState<boolean>(false);

    const logIn = async (e?: FormEvent) => {
        e?.preventDefault();
        setIsLoggingIn(true);
        await sendPostRequest(
            `api/logins/${username}/login`, {},
            resolveText("User_CouldNotLogIn"),
            `"${password}"`,
            async response => {
                const loginResult = await response.json() as Models.AccessControl.LoginResult;
                onLoggedIn(loginResult);
            },
            async response => {
                if(response) {
                    const authenticationResult = await response.json() as Models.AccessControl.AuthenticationResult;
                    showErrorAlert(resolveText("User_CouldNotLogIn"), resolveText(`AuthenticationErrorType_${authenticationResult.error}`));
                } else {
                    showErrorAlert(resolveText("User_CouldNotLogIn"));
                }
            },
            () => setIsLoggingIn(false)
        );
    }

    return (<Form onSubmit={logIn}>
        <FormGroup as={Row} className="my-3">
            <FormLabel column xs={3}>{resolveText("LocalLogin_Username")}</FormLabel>
            <Col>
                <FormControl
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
            </Col>
        </FormGroup>
        <FormGroup as={Row} className="my-3">
            <FormLabel column xs={3}>{resolveText("LocalLogin_Password")}</FormLabel>
            <Col>
                <PasswordFormControl
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </Col>
        </FormGroup>
        <Center>
            <AsyncButton
                type="submit"
                size="lg"
                isExecuting={isLoggingIn}
                activeText={resolveText("LogIn")}
                executingText={resolveText("LogIn")}
            />
        </Center>
    </Form>);

}