import '../styles/App.css';

import { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { buildLoadObjectFunc } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { RoomCard } from "../components/RoomCard";
import { SignalRConnectionIndicator } from "../components/SignalRConnectionIndicator";
import { Models } from "../types/models";
import { DepartmentSelector } from "../components/DepartmentSelector";
import { LoadingAlert } from "../../sharedCommonComponents/components/LoadingAlert";
import { BedDataViewerModal } from "../modals/BedDataViewerModal";
import { BedMeasurementType } from '../types/enums';

interface RoomsPageProps {}

export const RoomsPage = (props: RoomsPageProps) => {


    const [ showDepartmentSelector, setShowDepartmentSelector ] = useState<boolean>(true);
    const [ selectedDepartment, setSelectedDepartment ] = useState<Models.Organizations.Department>();
    const [ isLoadingRooms, setIsLoadingRooms ] = useState<boolean>(true);
    const [ rooms, setRooms ] = useState<Models.Organizations.Room[]>([]);
    const [ bedMeasurements, setBedMeasurements ] = useState<Models.BedManagement.BedMeasurements[]>([]);
    const [ showMeasurementHistoryModal, setShowMeasurementHistoryModal ] = useState<boolean>(false);
    const [ selectedRoom, setSelectedRoom ] = useState<Models.Organizations.Room>();
    const [ selectedBedPosition, setSelectedBedPosition ] = useState<string>();

    // const isAnyBabyCrying = useMemo(() => 
    //     bedMeasurements
    //     .flatMap(x => x.measurements)
    //     .filter(x => x.name === BedMeasurementType.BabyCrying)
    //     .some(x => x.value.toLowerCase() === 'true')
    // , [ bedMeasurements ]);

    // useEffect(() => {
    //     if(isAnyBabyCrying) {
    //         alert(`Baby is crying :'(`);
    //     }
    // }, [ isAnyBabyCrying ]);

    useEffect(() => {
        if(!selectedDepartment) {
            return;
        }
        setIsLoadingRooms(true);
        const loadRooms = buildLoadObjectFunc<Models.Organizations.Room[]>(
            `api/institutions/${selectedDepartment.institutionId}/departments/${selectedDepartment.id}/rooms`, {},
            resolveText("Rooms_CouldNotLoad"),
            result => {
                setRooms(result.sort((a,b) => a.name.localeCompare(b.name)));
            },
            undefined,
            () => setIsLoadingRooms(false)
        );
        loadRooms();
        const loadBedMeasurements = buildLoadObjectFunc<Models.BedManagement.BedMeasurements[]>(
            `api/institutions/${selectedDepartment.institutionId}/departments/${selectedDepartment.id}/bedMeasurements/latest`, {},
            resolveText("BedMeasurements_CouldNotLoad"),
            setBedMeasurements
        );
        loadBedMeasurements();
    }, [ selectedDepartment ]);

    const addOrUpdateBedMeasurement = useCallback((newMeasurements: Models.BedManagement.BedMeasurements) => {
        setBedMeasurements(state => state.map(x => 
            x.roomId === newMeasurements.roomId && x.bedPosition === newMeasurements.bedPosition 
                ? newMeasurements 
                : x
        ));
    }, []);

    const openMeasurementHistoryModal = (room: Models.Organizations.Room, bedPosition: string) => {
        setSelectedRoom(room);
        setSelectedBedPosition(bedPosition);
        setShowMeasurementHistoryModal(true);
    };

    return (<>
        <Row className="align-items-center">
            <Col xs="auto">
                <Button
                    variant={showDepartmentSelector ? 'secondary' : 'outline-secondary'}
                    onClick={() => setShowDepartmentSelector(state => !state)}
                >
                    <i className="fa fa-cog" />
                </Button>
            </Col>
            <Col xs="auto">
                <h1>{resolveText("BedMonitor")}</h1>
            </Col>
            <Col xs="auto">
                {!!selectedDepartment
                ? <SignalRConnectionIndicator 
                    onNewBedMeasurements={addOrUpdateBedMeasurement}
                /> : null}
            </Col>
        </Row>
        {showDepartmentSelector
        ? <DepartmentSelector
            onDepartmentSelected={setSelectedDepartment}
        /> : null}
        {!selectedDepartment ? null
        : isLoadingRooms ? <LoadingAlert />
        : rooms.map(room => {
            const roomBedMeasurements = bedMeasurements.filter(x => x.roomId === room.id);
            return (
            <RoomCard
                key={room.id}
                room={room}
                bedMeasurements={roomBedMeasurements}
                openMeasurementHistoryModal={openMeasurementHistoryModal}
            />);
        })}
        <BedDataViewerModal
            show={showMeasurementHistoryModal}
            onClose={() => setShowMeasurementHistoryModal(false)}
            room={selectedRoom}
            bedPosition={selectedBedPosition}
        />
    </>);

}
export default RoomsPage;