import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "flatpickr/dist/themes/material_green.css";
import './sharedCommonComponents/styles/common.css';

import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from './initializeApp';

initializeApp();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

