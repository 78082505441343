import { Modal } from "react-bootstrap";
import { Models } from "../types/models";
import { LoginForm } from "../components/LoginForm";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";

interface LoginModalProps {
    show: boolean;
    onClose: () => void;
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult) => void;
}

export const LoginModal = (props: LoginModalProps) => {

    const { show, onClose, onLoggedIn } = props;

    return (<Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{resolveText("LogIn")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <LoginForm
                onLoggedIn={(loginResult) => {
                    onLoggedIn(loginResult);
                    onClose();
                }}
            />
        </Modal.Body>
    </Modal>);

}