import { Row, Col, FormControl } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { useEffect, useState } from "react";
import { Models } from "../types/models";
import { buildLoadObjectFunc } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { LoadingAlert } from "../../sharedCommonComponents/components/LoadingAlert";

interface DepartmentSelectorProps {
    onDepartmentSelected: (department: Models.Organizations.Department | undefined) => void;
}

export const DepartmentSelector = (props: DepartmentSelectorProps) => {

    const { onDepartmentSelected } = props;

    const [ isLoadingInstitutions, setIsLoadingInstitutions ] = useState<boolean>(true);
    const [ institutions, setInstitutions ] = useState<Models.Organizations.Institution[]>([]);
    const [ selectedInstitution, setSelectedInstitution ] = useState<Models.Organizations.Institution>();

    const [ isLoadingDepartments, setIsLoadingDepartments ] = useState<boolean>(false);
    const [ departments, setDepartments ] = useState<Models.Organizations.Department[]>([]);
    const [ selectedDepartment, setSelectedDepartment ] = useState<Models.Organizations.Department>();

    useEffect(() => {
        onDepartmentSelected(selectedDepartment);
    }, [ selectedDepartment, onDepartmentSelected ]);

    useEffect(() => {
        setIsLoadingInstitutions(true);
        const loadInstitutions = buildLoadObjectFunc(
            'api/institutions', {},
            resolveText("Institutions_CouldNotLoad"),
            setInstitutions,
            undefined,
            () => setIsLoadingInstitutions(false)
        );
        loadInstitutions();
    }, []);

    useEffect(() => {
        if(institutions.length === 1) {
            setSelectedInstitution(institutions[0]);
        }
    }, [ institutions ]);

    useEffect(() => {
        if(!selectedInstitution) {
            return;
        }
        setIsLoadingDepartments(true);
        const loadDepartments = buildLoadObjectFunc(
            `api/institutions/${selectedInstitution.id}/departments`, {},
            resolveText("Departments_CouldNotLoad"),
            setDepartments,
            undefined,
            () => setIsLoadingDepartments(false)
        );
        loadDepartments();
    }, [ selectedInstitution ]);

    useEffect(() => {
        if(departments.length === 1) {
            setSelectedDepartment(departments[0]);
        }
    }, [ departments ]);

    if(isLoadingInstitutions || isLoadingDepartments) {
        return (<LoadingAlert />);
    }

    return (
    <Row className="my-3">
        <Col>
            <FormControl
                as="select"
                value={selectedInstitution?.id ?? ''}
                onChange={e => setSelectedInstitution(institutions.find(x => x.id === e.target.value))}
            >
                <option value="">{resolveText("PleaseSelect...")}</option>
                {institutions.map(institution => (
                    <option key={institution.id} value={institution.id}>{institution.name}</option>
                ))}
            </FormControl>
        </Col>
        <Col>
            <FormControl
                as="select"
                value={selectedDepartment?.id ?? ''}
                onChange={e => setSelectedDepartment(departments.find(x => x.id === e.target.value))}
            >
                <option value="">{resolveText("PleaseSelect...")}</option>
                {departments.map(department => (
                    <option key={department.id} value={department.id}>{department.name}</option>
                ))}
            </FormControl>
        </Col>
    </Row>);

}