import { PropsWithChildren, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { LoadingAlert } from "../../sharedCommonComponents/components/LoadingAlert";
import { LogInLogOut } from "./LogInLogOut";
import { Models } from "../types/models";
import { Container } from "react-bootstrap";

interface LayoutProps extends PropsWithChildren {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult) => void;
    onLoggedOut: () => void;
}

export const Layout = (props: LayoutProps) => {

    const { onLoggedIn, onLoggedOut } = props;

    return (<>
        <ToastContainer
            theme='colored'
            toastStyle={{ top: '100px'}}
        />
        <LogInLogOut 
            onLoggedIn={onLoggedIn}
            onLoggedOut={onLoggedOut}
        />
        <Container style={{ maxWidth: '95%' }}>
            <Suspense fallback={<LoadingAlert />}>
                {props.children}
            </Suspense>
        </Container>
    </>);

}