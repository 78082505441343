import { useCallback, useState } from 'react';
import { Layout } from './localComponents/components/Layout';
import { Models } from './localComponents/types/models';
import UserContext from './localComponents/contexts/UserContext';
import { apiClient } from './sharedCommonComponents/communication/ApiClient';
import { accessTokenSessionStorageKey, accountSessionStorageKey, csrfTokenSessionStorageKey } from './sharedCommonComponents/helpers/Constants';
import LoginPage from './localComponents/pages/LoginPage';
import RoomsPage from './localComponents/pages/RoomsPage';

export const App = () => {

    const [ user, setUser ] = useState<Models.AccessControl.Account | undefined>(() => {
        const serializedAccount = sessionStorage.getItem(accountSessionStorageKey);
        if(!serializedAccount) {
            return undefined;
        }
        return JSON.parse(serializedAccount);
    });


    const onLoggedIn = useCallback((loginResult: Models.AccessControl.LoginResult) => {
        const authenticationResult = loginResult.authenticationResult;
        if(authenticationResult.isAuthenticated) {
            apiClient.instance!.accessToken = authenticationResult.jwt!.rawAccessToken;
            apiClient.instance!.isLoggedIn = true;
            apiClient.instance!.csrfToken = authenticationResult.csrfToken!;
            sessionStorage.setItem(accessTokenSessionStorageKey, authenticationResult.jwt!.rawAccessToken);
            sessionStorage.setItem(csrfTokenSessionStorageKey, authenticationResult.csrfToken!);
            setUser(loginResult.account);
            sessionStorage.setItem(accountSessionStorageKey, JSON.stringify(loginResult.account));
        }
    }, []);

    const onLoggedOut = useCallback(() => {
        apiClient.instance!.accessToken = undefined;
        apiClient.instance!.isLoggedIn = false;
        setUser(undefined);
        sessionStorage.removeItem(accessTokenSessionStorageKey);
        sessionStorage.removeItem(csrfTokenSessionStorageKey);
        sessionStorage.removeItem(accountSessionStorageKey);
    }, []);

    if(!user) {
        return (<LoginPage
            onLoggedIn={onLoggedIn}
        />);
    }

    return (<UserContext.Provider value={user}>
        <Layout
            onLoggedIn={onLoggedIn}
            onLoggedOut={onLoggedOut}
        >
            <RoomsPage />
        </Layout>
    </UserContext.Provider>);
}

export default App;
