import { Container, Card, Col, Row } from "react-bootstrap";
import { LoginForm } from "../components/LoginForm";
import { Models } from "../types/models";
import { ToastContainer } from "react-toastify";

interface LoginPageProps {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult) => void;
}

export const LoginPage = (props: LoginPageProps) => {

    const { onLoggedIn } = props;

    return (<>
        <ToastContainer
            theme='colored'
        />
        <Container>
            <Row>
                <Col>
                    <Card 
                        style={{ marginTop: '200px' }} 
                        bg='light' 
                        className='pb-3 px-5'
                    >
                        <Card.Body>
                            <LoginForm
                                onLoggedIn={onLoggedIn}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>);

}
export default LoginPage;