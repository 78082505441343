import { Badge, Card } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../types/models";
import { useMemo } from "react";
import { differenceInSeconds } from "date-fns";
import { UniformGrid } from "../../sharedCommonComponents/components/UniformGrid";
import { BedMeasurementType } from "../types/enums";
import { BedMeasurementValueVisualization } from "./BedMeasurementValueVisualization";

interface BedCardProps {
    bedPosition: string;
    bedMeasurements?: Models.BedManagement.BedMeasurements;
    openMeasurementHistoryModal: () => void;
}

export const BedCard = (props: BedCardProps) => {

    const { bedPosition, bedMeasurements, openMeasurementHistoryModal } = props;
    const secondsSinceLastMeasurement = useMemo(() => {
        if(!bedMeasurements) {
            return Infinity;
        }
        return differenceInSeconds(new Date(), new Date(bedMeasurements.timestamp));
    }, [ bedMeasurements ]);
    const isConnectionLost = useMemo(() => secondsSinceLastMeasurement > 120, [ secondsSinceLastMeasurement ]);
    const isBabyCrying = useMemo(() => bedMeasurements?.measurements
        .find(x => x.name === BedMeasurementType.BabyCrying && x.value.toLowerCase() === 'true') ?? false
    , [ bedMeasurements ]);

    return (<Card className="bedcard">
        <Card.Header 
            className={`py-0${!isConnectionLost ? ' clickable' : ''}`}
            onClick={!isConnectionLost ? openMeasurementHistoryModal : undefined}
        >
            <div className="d-flex">
                <Card.Title 
                    className={isConnectionLost ? 'text-secondary' : undefined}
                >
                    <span className={`text-nowrap${isBabyCrying ? ' text-danger' : ''}`}>
                        {resolveText("Bed")} {bedPosition}
                    </span>
                </Card.Title>
                {isConnectionLost
                ? <div className="bedcard-connectionlost-icon">
                    <Badge
                        bg="secondary"
                        title={resolveText("ConnectionLost")}
                    >
                        <i className="fa fa-chain-broken" />
                    </Badge>
                </div> : null}
            </div>
        </Card.Header>
        <Card.Body className={isConnectionLost ? 'text-secondary p-1' : 'p-1'}>
            <UniformGrid
                items={Object.values(BedMeasurementType).map(measurementType => {
                    const typeMeasurement = bedMeasurements?.measurements?.find(x => x.name === measurementType);
                    return (<div
                        key={measurementType}
                        className="text-center text-nowrap"
                    >
                        <BedMeasurementValueVisualization 
                            measurement={typeMeasurement}
                            isConnectionLost={isConnectionLost}
                        />
                    </div>);
                })}
                columnCount={3}
                size="xs"
                className="pe-1"
            />
        </Card.Body>
    </Card>);

}

