import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../types/models";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { apiClient } from "../../sharedCommonComponents/communication/ApiClient";
import { showErrorAlert } from "../../sharedCommonComponents/helpers/AlertHelpers";

interface SignalRConnectionIndicatorProps {
    onNewBedMeasurements: (bedState: Models.BedManagement.BedMeasurements) => void;
}

const getColorFromConnectionState = (connectionState: HubConnectionState) => {
    switch(connectionState) {
        case HubConnectionState.Disconnected:
            return 'secondary';
        case HubConnectionState.Connected:
            return 'success';
        case HubConnectionState.Connecting:
        case HubConnectionState.Disconnecting:
            return 'warning';
        case HubConnectionState.Reconnecting:
            return 'danger'
        default:
            throw new Error(`Unknown connection state ${connectionState}`);
    }
}

export const SignalRConnectionIndicator = (props: SignalRConnectionIndicatorProps) => {

    const { onNewBedMeasurements } = props;

    const [ connection, setConnection ] = useState<HubConnection>();
    const connectionState = connection?.state ?? HubConnectionState.Disconnected;

    useEffect(() => {
        const buildConnection = async () => {
            const response = await apiClient.instance!.get('api/logins/signalr-accesstoken');
            const jwt = await response.json() as Models.AccessControl.Jwt;
            const accessToken = jwt.rawAccessToken;
            const connection = new HubConnectionBuilder()
                .withUrl(apiClient.instance!.buildUrl('/hubs/bedMeasurements'), { accessTokenFactory: () => accessToken })
                .withAutomaticReconnect()
                .build();
            setConnection(connection);
        }
        buildConnection();
    }, []);

    useEffect(() => {
        if(!connection) {
            return;
        }
        connection.on('OnNewBedMeasurements', onNewBedMeasurements);
        return () => {
            connection.off('OnNewBedMeasurements');
        }
    }, [ connection, onNewBedMeasurements]);

    useEffect(() => {
        if(!connection) {
            return;
        }
        const startConnection = async () => {
            try {
                await connection.start();
            } catch {
                showErrorAlert(resolveText("SignalR_CouldNotConnect"));
            }
        };
        startConnection();
        return () => {
            connection.stop();
        }
    }, [ connection ]);

    return (<Badge
        bg={getColorFromConnectionState(connectionState)}
    >
        {resolveText(`HubConnectionState_${connectionState}`)}
    </Badge>);

}