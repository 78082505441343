import { useCallback, useMemo, useState } from "react";
import { Models } from "../types/models";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { groupBy, last, sortByTimeAscending } from "../../sharedCommonComponents/helpers/CollectionHelpers";
import { BedMeasurementType } from "../types/enums";
import { addHours, differenceInSeconds } from "date-fns";

interface BedMeasurementsChartsProps {
    measurements: Models.BedManagement.BedMeasurements[];
    onTimeRangeChanged: (timeRangeStart: Date, timeRangeEnd: Date) => void;
}

const tryGetNumberOrReturnInput = (input: string) => {
    if(/-?[0-9]+(.[0-9]+)?/.test(input)) {
        return Number(input);
    }
    const lowerCaseInput = input.toLowerCase();
    if(lowerCaseInput === 'true') {
        return 1;
    }
    if(lowerCaseInput === 'false') {
        return 0;
    }
    return input;
}
const isBooleanMeasurementType = (measurementType: string) => {
    return [ 
        BedMeasurementType.BabyCrying as string, 
        BedMeasurementType.DoorClosed as string 
    ].includes(measurementType);
}
interface Zoom {
    min: number;
    max: number;
}
export const BedMeasurementsCharts = (props: BedMeasurementsChartsProps) => {

    const { measurements, onTimeRangeChanged } = props;

    const defaultZoom = useMemo(() => ({
        min: addHours(new Date(), -24).getTime(),
        max: new Date().getTime()
    } as Zoom), []);
    const [ zoom, setZoom ] = useState<Zoom>(defaultZoom);
    const timeOrderedMeasurements = useMemo(() => sortByTimeAscending(measurements, x => new Date(x.timestamp)), [ measurements ]);
    const timeRangeStart = useMemo(() => timeOrderedMeasurements.length > 0 ? timeOrderedMeasurements[0].timestamp : undefined, [ timeOrderedMeasurements ]);
    const timeRangeEnd = useMemo(() => timeOrderedMeasurements.length > 0 ? last(timeOrderedMeasurements)!.timestamp : undefined, [ timeOrderedMeasurements ]);

    const series = useMemo(() => {
        const dataPoints: {
            name: string;
            value: string;
            time: number;
        }[] = timeOrderedMeasurements.flatMap(x1 => x1.measurements.map(x2 => ({
            ...x2,
            time: new Date(x1.timestamp).getTime()
        })));
        const series: ApexAxisChartSeries = groupBy(dataPoints, x => x.name)
            .map(g => ({
                name: g.key,
                data: g.items.map(dataPoint => ({
                    x: dataPoint.time,
                    y: tryGetNumberOrReturnInput(dataPoint.value)
                }))
            }));
        return series;
    }, [ timeOrderedMeasurements ]);
    const measurementTypes = useMemo(() => series.map(x => x.name!), [ series ]);

    const onZoomed = useCallback((_: any, params?: ApexOptions) => {
        if(!params) {
            return;
        }
        if(!params.xaxis?.min || !params.xaxis.max) {
            return;
        }
        const newTimeRangeStart = new Date(params.xaxis.min).toISOString() as any;
        const newTimeRangeEnd = new Date(params.xaxis.max).toISOString() as any;
        const timeRangeHasChangedSignificantly = !timeRangeStart 
            || Math.abs(differenceInSeconds(newTimeRangeStart, timeRangeStart)) > 60
            || !timeRangeEnd 
            || Math.abs(differenceInSeconds(newTimeRangeEnd, timeRangeEnd)) > 60
        if(timeRangeHasChangedSignificantly) {
            onTimeRangeChanged(newTimeRangeStart, newTimeRangeEnd);
        }
    }, [ onTimeRangeChanged, timeRangeStart, timeRangeEnd ]);

    const options = useMemo(() => ({
        chart: {
            type: 'line',
            animations: {
                enabled: false
            },
            events: {
                zoomed: onZoomed,
                beforeZoom: (e, { xaxis }: ApexOptions) => {
                    if(!xaxis?.min || !xaxis?.max) {
                        return;
                    }
                    const offset = e.minX;
                    console.log("Chart", { minX: e.minX - offset, maxX: e.maxX - offset });
                    console.log("xaxis", { min: xaxis.min - offset, max: xaxis.max - offset });
                    if(xaxis.min <= e.minX && xaxis.max >= e.maxX) {
                        console.log("Zoom further out");
                        const timeDelta = e.maxX - e.minX;
                        const newZoom = {
                            min: e.minX - 0.5 * timeDelta,
                            max: e.maxX + 0.5 * timeDelta
                        };
                        //setZoom(newZoom);
                        return {
                            xaxis: newZoom
                        };
                    }
                    // setZoom({
                    //     min: xaxis.min,
                    //     max: xaxis.max
                    // });
                },
                beforeResetZoom: () => {
                    return {
                        xaxis: {
                            min: defaultZoom.min,
                            max: defaultZoom.max
                        }
                    } as ApexOptions;
                }
            }
        },
        stroke: {
            curve: measurementTypes
                .map(measurementType => 
                    isBooleanMeasurementType(measurementType) 
                    ? 'stepline' 
                    : 'smooth'
                )
        },
        xaxis: {
            type: 'datetime',
            title: {
                text: 'Time'
            }
        },
        yaxis: [
            {
                show: true,
                title: {
                    text: 'Door closed / Baby crying'
                },
                min: -0.2,
                max: 1.2,
                seriesName: [ BedMeasurementType.DoorClosed, BedMeasurementType.BabyCrying ]
            },
            {
                show: true,
                title: {
                    text: 'Temperature (°C)'
                },
                min: 18,
                max: 50,
                seriesName: [ BedMeasurementType.Temperature ]
            },
            {
                show: true,
                title: {
                    text: 'Other'
                }
            }
        ]
    } as ApexOptions), [ measurementTypes, defaultZoom, zoom, onZoomed ]);

    return (<Chart
        type="line"
        options={options}
        series={series}
        height={600}
    />);

}