import { useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import { AsyncButton } from "../../sharedCommonComponents/components/AsyncButton";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { Button } from "react-bootstrap";
import { Models } from "../types/models";
import { LoginModal } from "../modals/LoginModal";
import { sendPostRequest } from "../../sharedCommonComponents/helpers/StoringHelpers";

interface LogInLogOutProps {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult) => void;
    onLoggedOut: () => void;
}

export const LogInLogOut = (props: LogInLogOutProps) => {

    const { onLoggedIn, onLoggedOut } = props;

    const user = useContext(UserContext);
    const [ isLoggingOut, setIsLoggingOut ] = useState<boolean>(false);
    const [ showLoginModal, setShowLoginModal ] = useState<boolean>(false);

    const logOut = async () => {
        setIsLoggingOut(true);
        await sendPostRequest(
            'api/logins/logout', {},
            resolveText("User_CouldNotLogOut"),
            null,
            async () => {
            },
            undefined,
            () => {
                setIsLoggingOut(false);
                onLoggedOut();
            }
        );
    }

    let stateDependentControls = null;
    if(!!user) {
        stateDependentControls = (<>
            <AsyncButton
                variant="danger"
                isExecuting={isLoggingOut}
                onClick={logOut}
                activeText={resolveText("LogOut")}
                executingText={resolveText("LogOut")}
            />
        </>);
    } else {
        stateDependentControls = (<>
            <Button
                onClick={() => setShowLoginModal(true)}
            >
                {resolveText("LogIn")}
            </Button>
            <LoginModal 
                show={showLoginModal}
                onClose={() => setShowLoginModal(false)}
                onLoggedIn={onLoggedIn}
            />
        </>);
    }

    return (<div
        style={{
            position: 'absolute',
            top: '20px',
            right: '20px'
        }}
    >
        {stateDependentControls}
    </div>);

}