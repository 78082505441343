import { Language } from "./localComponents/types/enums";
import { Globalizer, defaultGlobalizer } from "./sharedCommonComponents/helpers/Globalizer";
import englishTranslation from './localComponents/resources/translation.en.json';
import germanTranslation from './localComponents/resources/translation.de.json';
import { ApiClient, apiClient } from "./sharedCommonComponents/communication/ApiClient";
import { accessTokenSessionStorageKey, csrfTokenSessionStorageKey } from "./sharedCommonComponents/helpers/Constants";

export const initializeApp = () => {
    defaultGlobalizer.instance = new Globalizer(
        navigator.languages.map(lang => lang.substring(0, 2)),
        Language.en,
        [ englishTranslation, germanTranslation ]
    );
    apiClient.instance = window.location.hostname.toLowerCase() === "localhost"
        ? new ApiClient(window.location.hostname, 44362)
        : new ApiClient(window.location.hostname, 443);
    const storedAccessToken = sessionStorage.getItem(accessTokenSessionStorageKey);
    if(!!storedAccessToken) {
        apiClient.instance!.isLoggedIn = true;
        apiClient.instance!.accessToken = storedAccessToken;
    }
    const storedCsrfToken = sessionStorage.getItem(csrfTokenSessionStorageKey);
    if(!!storedCsrfToken) {
        apiClient.instance!.csrfToken = storedCsrfToken;
    }
}