import { FormGroup, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { addHours } from "date-fns";
import { Models } from "../types/models";
import { buildLoadObjectFunc } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { LoadingAlert } from "../../sharedCommonComponents/components/LoadingAlert";
import { NoEntriesAlert } from "../../sharedCommonComponents/components/NoEntriesAlert";
import { BedMeasurementsCharts } from "../components/BedMeasurementsCharts";
import { DateRangeFormControl } from "../../sharedCommonComponents/communication/FormControls/DateRangeFormControl";
import { Center } from "../../sharedCommonComponents/components/Center";

interface BedDataViewerModalProps {
    show: boolean;
    onClose: () => void;
    room?: Models.Organizations.Room;
    bedPosition?: string;
}

export const BedDataViewerModal = (props: BedDataViewerModalProps) => {

    const { show, onClose, room, bedPosition } = props;

    const [ timeRangeStart, setTimeRangeStart ] = useState<Date | undefined>(() => addHours(new Date(), -24).toISOString() as any);
    const [ timeRangeEnd, setTimeRangeEnd ] = useState<Date | undefined>(() => new Date().toISOString() as any);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ measurements, setMeasurements ] = useState<Models.BedManagement.BedMeasurements[]>([]);

    useEffect(() => {
        if(!room || !bedPosition) {
            return;
        }
        setTimeRangeStart(addHours(new Date(), -24).toISOString() as any);
        setTimeRangeEnd(new Date().toISOString() as any);
    }, [ room, bedPosition ]);

    useEffect(() => {
        if(!timeRangeStart || !timeRangeEnd || !room || !bedPosition) {
            setMeasurements([]);
            return;
        }
        setIsLoading(true);
        const loadMeasurements = buildLoadObjectFunc(
            `api/rooms/${room.id}/beds/${bedPosition}/bedMeasurements`, {
                'timeRangeStart': timeRangeStart as any,
                'timeRangeEnd': timeRangeEnd as any,
                'disableSmoothing': 'false'
            },
            resolveText("BedMeasurements_CouldNotLoad"),
            setMeasurements,
            undefined,
            () => setIsLoading(false)
        );
        loadMeasurements();
    }, [ timeRangeStart, timeRangeEnd, room, bedPosition ]);

    return (<Modal
        show={show}
        onHide={onClose}
        fullscreen
        backdrop="static"
    >
        <Modal.Header closeButton>
            <Modal.Title>{room?.name} {bedPosition}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Center>
                <FormGroup style={{ minWidth: '400px' }}>
                    <DateRangeFormControl
                        enableTime
                        value={timeRangeStart && timeRangeEnd ? [ timeRangeStart, timeRangeEnd ] : undefined}
                        onChange={(startDate, endDate) => {
                            setTimeRangeStart(startDate);
                            setTimeRangeEnd(endDate);
                        }}
                    />
                </FormGroup>
            </Center>
            {isLoading ? <LoadingAlert />
            : measurements.length === 0 ? <NoEntriesAlert />
            : <BedMeasurementsCharts 
                measurements={measurements}
                onTimeRangeChanged={(startDate, endDate) => {
                    setTimeRangeStart(startDate);
                    setTimeRangeEnd(endDate);
                }}
            />}
            <Center>
                <Button
                    variant="secondary"
                    onClick={onClose}
                >
                    {resolveText("Close")}
                </Button>
            </Center>
        </Modal.Body>
    </Modal>);

}