import { Badge } from "react-bootstrap";
import { BedMeasurementType } from "../types/enums";
import { Models } from "../types/models";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";

interface BedMeasurementValueVisualizationProps {
    measurement?: Models.BedManagement.BedMeasurement;
    isConnectionLost?: boolean;
}
export const BedMeasurementValueVisualization = (props: BedMeasurementValueVisualizationProps) => {

    const { measurement, isConnectionLost } = props;

    if(isConnectionLost || !measurement) {
        return (<span className="text-secondary">-</span>);
    }

    const title = `${resolveText(`BedMeasurementType_${measurement.name}`)}: ${measurement.value}`;
    switch(measurement.name) {
        case BedMeasurementType.DoorClosed:
            if(measurement.value.toLowerCase() === 'true') {
                return (<i className="fa fa-lock" title={title} />);
            } else {
                return (<span className="text-danger">
                    <i className="fa fa-unlock" title={title} />
                </span>);
            }
        case BedMeasurementType.BabyCrying:
            if(measurement.value.toLowerCase() === 'true') {
                return (<Badge pill 
                    bg="danger" 
                    className="pulsate" 
                    title={title}
                >
                    <i className="fa fa-frown-o" />
                </Badge>);
            } else {
                return (<i className="fa fa-smile-o" title={title} />);
            }
        case BedMeasurementType.NoiseLevel:
            const noiseLevel = Number(measurement.value);
            if(noiseLevel > 50) {
                return (<i className="fa fa-volume-up" title={title} />);
            } else if(noiseLevel > 20) {
                return (<i className="fa fa-volume-down" title={title} />);
            } else if(noiseLevel > 10) {
                return (<i className="fa fa-volume-off" title={title} />);
            }
            return (<i className="fa fa-volume-off" />);
        case BedMeasurementType.AmbientLight:
            const ambientLight = Number(measurement.value);
            if(ambientLight > 50) {
                return (<i className="fa fa-sun-o" title={title} />);
            }
            return (<i className="fa fa-moon-o" title={title} />);
        case BedMeasurementType.Temperature:
        {
            const temperature = Number(measurement.value);
            let icon: string = 'fa-thermometer-';
            let textColor: string | undefined = 'text-success';
            if(temperature > 34) {
                icon = 'fa-thermometer-full';
                textColor = 'text-danger';
            } else if(temperature > 32) {
                icon = 'fa-thermometer-three-quarters';
                textColor = 'text-danger';
            } else if(temperature > 30) {
                icon = 'fa-thermometer-half';
                textColor = undefined;
            } else if(temperature > 28) {
                icon = 'fa-thermometer-quarter';
                textColor = 'text-primary';
            } else if(temperature > 26) {
                icon = 'fa-thermometer-empty';
                textColor = 'text-primary';
            } else {
                icon = 'fa-snowflake-o';
                textColor = 'text-primary';
            }
            return (<span
                className={textColor}
                title={title}
            >
                <i className={`fa ${icon}`} />
            </span>);
        }
        case BedMeasurementType.Humidity:
        {
            const humidity = Number(measurement.value);
            let icon: string = 'fa-cloud';
            let textColor: string | undefined = 'text-dark';
            if(humidity > 60) {
                icon = 'fa-cloud-upload';
                textColor = 'text-danger';
            } else if(humidity > 40) {
                textColor = undefined;
            } else {
                icon = 'fa-cloud-download';
                textColor = 'text-primary';
            }
            return (<span 
                className={textColor}
                title={title}
            >
                <i className={`fa ${icon}`} />
            </span>);
        }
        default:
            return (<span title={title}>{measurement.value}</span>);
    }

}