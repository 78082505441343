import { Card, Col, Row } from "react-bootstrap";
import { BedCard } from "./BedCard";
import { Models } from "../types/models";

interface RoomCardProps {
    room: Models.Organizations.Room;
    bedMeasurements: Models.BedManagement.BedMeasurements[];
    openMeasurementHistoryModal: (room: Models.Organizations.Room, bedPosition: string) => void;
}

export const RoomCard = (props: RoomCardProps) => {

    const { room, bedMeasurements, openMeasurementHistoryModal } = props;

    return (
    <Card className="mx-2 my-2 d-inline-block">
        <Card.Header className="py-0">
            <Row>
                <Col>
                    <Card.Title>{room.name}</Card.Title>
                </Col>
                <Col xs="auto">
                    
                </Col>
            </Row>
        </Card.Header>
        <Card.Body className="px-1 py-0">
            {room.bedPositions.map(bedPosition => {
                const thisBedMeasurements = bedMeasurements.find(x => x.bedPosition === bedPosition);
                return (<BedCard
                    key={bedPosition}
                    bedPosition={bedPosition}
                    bedMeasurements={thisBedMeasurements}
                    openMeasurementHistoryModal={() => openMeasurementHistoryModal(room, bedPosition)}
                />);
            })}
        </Card.Body>
    </Card>);

}